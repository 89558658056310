<template>
  <van-pull-refresh v-model="isLoading" :head-height="82" @refresh="onRefresh">
    <!-- 下拉提示 -->
    <template #pulling="props">
      <div class="tips-box">
        <NewkiSvg name="newki-pulling" :size="40" :filled="true" />
        <span class="refresh-text">{{ t('common.pullRefresh') }}</span>
      </div>
    </template>

    <!-- 释放提示 -->
    <template #loosing>
      <div class="tips-box">
        <NewkiSvg name="newki-normal" :size="40" :filled="true" />
        <span class="refresh-text">{{ t('common.pullLoosing') }}</span>
      </div>
    </template>

    <!-- 加载提示 -->
    <template #loading>
      <div class="tips-box">
        <NewkiSvg name="newki-normal" :size="40" :filled="true" />
        <span class="refresh-text">{{ t('common.loading') }}</span>
      </div>
    </template>
    <slot></slot>
  </van-pull-refresh>
</template>

<script setup lang="ts">
import NewkiSvg from '~/components/NewkiSvg.vue'

const { t } = useI18n()
const emit = defineEmits(['refresh', 'update:modelValue'])

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const isLoading = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

function onRefresh() {
  emit('refresh')
}
</script>

<style scoped lang="scss">
.tips-box {
  height: 100%;
  @include flex(column, center, center);
  .refresh-text {
    @extend .text-assist;
    color: $text-color-assist;
    text-align: center;
  }
  .text {
    height: 500px;
  }
}
</style>
